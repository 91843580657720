<template>
  <header
    :class="`global-header ${isMobileMenuOpened ? ' is-header-active' : ''}`"
    id="navcontent"
  >
    <div
      v-if="isDesktop && hasOpenedSubMenu"
      id="mask"
      class="mask"
      @click="hideSubmenu()"
    ></div>
    <div ref="headerRef" class="global-header-inner">
      <nav
        v-show="isScrolledPage"
        role="navigation"
        aria-label="Minimized navigation"
        class="min-nav hidden-mobile"
      >
        <div class="wrapper">
          <a href="/" :title="content.logo_alt || false">
            <nuxt-img
              :src="content.logo.filename"
              :width="logoDimensions[0]"
              :height="logoDimensions[1]"
              :alt="content.logo_alt"
              class="logo"
              provider="storyblok"
            />
          </a>
          <ul v-if="minNavMenu?.length" class="main-menu-list">
            <li
              v-for="(menuItem, index) in minNavMenu"
              :key="menuItem._uid"
              :class="`main-menu-item${
                currentMainMenuItem === index ? ' current' : ''
              }`"
            >
              <NuxtLink
                v-if="menuItem.link && !menuItem.sub_menu?.length"
                class="menu-link"
                :to="strippedURL(menuItem.link)"
                :target="menuItem.link.target || '_self'"
                @click="
                  trackingStore.sendTrackingData({
                    event: 'click.action',
                    click: useSnakeCaseFilter(menuItem.label),
                    click_chapter1: 'menu',
                    click_chapter2: 'link',
                  })
                "
                >{{ menuItem.label
                }}<SvgIcon v-if="menuItem.icon" :name="`func_${menuItem.icon}`"
              /></NuxtLink>
              <template v-if="menuItem.sub_menu?.length">
                <button
                  :aria-expanded="currentMainMenuItem === index"
                  class="menu-link has-submenu"
                  @click="toggleSubmenu(index, $event)"
                >
                  {{ menuItem.label
                  }}<SvgIcon
                    v-if="menuItem.icon"
                    :name="`func_${menuItem.icon}`"
                  />
                </button>
                <div class="sub-menu">
                  <button
                    v-if="!isDesktop"
                    class="close-submenu"
                    @click="hideSubmenu()"
                  >
                    {{ menuItem.label }}
                  </button>
                  <ul
                    v-if="
                      !menuItem.sub_menu_grid ||
                      menuItem.sub_menu_grid?.length === 0
                    "
                    class="sub-menu-list"
                  >
                    <li
                      v-for="submenuItem in menuItem.sub_menu"
                      :key="submenuItem._uid"
                      @click="
                        trackingStore.sendTrackingData({
                          event: 'click.navigation',
                          click: useSnakeCaseFilter(submenuItem.link_label),
                          click_chapter1: 'sub_menu',
                          click_chapter2: 'link',
                        })
                      "
                    >
                      <NuxtLink
                        class="sub-menu-link"
                        :to="strippedURL(submenuItem.link_url)"
                        :target="
                          submenuItem.link_url.target === '_blank'
                            ? '_blank'
                            : '_self'
                        "
                      >
                        {{ submenuItem.link_label }}</NuxtLink
                      >
                    </li>
                  </ul>
                  <div
                    v-if="menuItem.sub_menu_grid?.length > 0"
                    class="sub-menu-wrapper"
                  >
                    <SubMenuGrid :grid="menuItem.sub_menu_grid" />
                    <div
                      v-if="
                        menuItem.sub_menu_grid?.length < 4 && menuItem.has_push
                      "
                      class="push-wrapper"
                    >
                      <MediaTextCta
                        :blok="pushData(menuItem)"
                        :level="2"
                        :is-centered="false"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </li>
          </ul>
        </div>
        <ul class="cta-list">
          <li v-if="locatorMenuItem.link">
            <NuxtLink
              class="locator-cta"
              :title="locatorMenuItem.label"
              :to="strippedURL(locatorMenuItem.link)"
              :target="locatorMenuItem.link.target || '_self'"
              @click="
                trackingStore.sendTrackingData({
                  event: 'click.action',
                  click: useSnakeCaseFilter(locatorMenuItem.label),
                  click_chapter1: 'menu',
                  click_chapter2: 'link',
                })
              "
              ><SvgIcon name="func_locator"
            /></NuxtLink>
          </li>
          <li v-if="content.contact_cta_label">
            <div class="contact-cta-wrapper parent-bg">
              <SimpleButton
                solid
                @click="
                  openContactModal();
                  trackingStore.sendTrackingData({
                    event: 'click.action',
                    click: useSnakeCaseFilter(content.contact_cta_label),
                    click_chapter1: 'menu',
                    click_chapter2: 'CTA',
                  });
                "
                >{{ content.contact_cta_label }}</SimpleButton
              >
            </div>
          </li>
        </ul>
      </nav>
      <nav
        v-show="!isScrolledPage"
        role="navigation"
        aria-label="Meta nav"
        class="meta-nav hidden-mobile"
      >
        <ul v-if="content.corporate_menu?.length" class="corporate-menu">
          <li
            v-for="(menuItem, index) in content.corporate_menu"
            :key="menuItem._uid"
            :class="currentCorporateMenuItem === index ? 'current' : ''"
          >
            <NuxtLink
              v-if="menuItem.link && !menuItem.sub_menu?.length"
              class="menu-link"
              :to="strippedURL(menuItem.link)"
              :target="menuItem.link.target || '_self'"
              >{{ menuItem.label
              }}<SvgIcon v-if="menuItem.icon" :name="`func_${menuItem.icon}`"
            /></NuxtLink>
            <template v-if="menuItem.sub_menu?.length">
              <button
                :aria-expanded="currentCorporateMenuItem === index"
                class="menu-link has-submenu"
                @click="toggleCorporateSubmenu(index, $event)"
              >
                {{ menuItem.label
                }}<SvgIcon
                  v-if="menuItem.icon"
                  :name="`func_${menuItem.icon}`"
                />
              </button>
              <div class="sub-menu" tabindex="-1">
                <ul class="sub-menu-list">
                  <li
                    v-for="submenuItem in menuItem.sub_menu"
                    :key="submenuItem._uid"
                    @click="
                      trackingStore.sendTrackingData({
                        event: 'click.action',
                        click: useSnakeCaseFilter(submenuItem.link_label),
                        click_chapter1: 'sub_menu',
                        click_chapter2: 'link',
                      })
                    "
                  >
                    <NuxtLink
                      class="sub-menu-link"
                      :to="strippedURL(submenuItem.link_url)"
                      :target="
                        submenuItem.link_url.target === '_blank'
                          ? '_blank'
                          : '_self'
                      "
                      :tabindex="currentCorporateMenuItem === index ? 0 : -1"
                    >
                      {{ submenuItem.link_label }}</NuxtLink
                    >
                  </li>
                </ul>
              </div>
            </template>
          </li>
        </ul>
        <a href="/" :title="content.logo_alt || false">
          <nuxt-img
            :src="content.logo.filename"
            :width="logoDimensions[0]"
            :height="logoDimensions[1]"
            :alt="content.logo_alt"
            class="logo"
            provider="storyblok"
          />
        </a>
        <div
          v-if="content.contact_cta_label"
          class="contact-cta-wrapper parent-bg"
        >
          <SimpleButton
            solid
            @click="
              openContactModal();
              trackingStore.sendTrackingData({
                event: 'click.action',
                click: useSnakeCaseFilter(content.contact_cta_label),
                click_chapter1: 'menu',
                click_chapter2: 'CTA',
              });
            "
            >{{ content.contact_cta_label }}</SimpleButton
          >
        </div>
      </nav>
      <nav
        ref="mobileHeaderRef"
        role="navigation"
        aria-label="Meta nav"
        class="meta-nav hidden-tablet"
      >
        <NuxtLink to="/" :title="content.logo_alt || false">
          <nuxt-img
            :src="content.logo.filename"
            :width="logoDimensions[0]"
            :height="logoDimensions[1]"
            :alt="content.logo_alt"
            class="logo"
            loading="lazy"
            provider="storyblok"
          />
        </NuxtLink>
        <button
          class="menu-burger"
          :title="useMicroCopy('globalheader.burgerbutton.label')"
          :aria-expanded="isMobileMenuOpened"
          @click="showHideMobileMenu()"
        >
          <SvgIcon v-if="isMobileMenuOpened" name="func_cross" />
          <SvgIcon v-else name="func_menu" />
        </button>
      </nav>
      <div v-show="!isScrolledPage" class="menu-wrapper">
        <nav role="navigation" aria-label="Main menu" class="main-menu">
          <ul v-if="content.main_menu?.length" class="main-menu-list">
            <li
              v-for="(menuItem, index) in content.main_menu"
              :key="menuItem._uid"
              :class="`main-menu-item${
                currentMainMenuItem === index ? ' current' : ''
              }`"
            >
              <NuxtLink
                v-if="menuItem.link && !menuItem.sub_menu?.length"
                class="menu-link"
                :to="strippedURL(menuItem.link)"
                :target="menuItem.link.target || '_self'"
                @click="
                  trackingStore.sendTrackingData({
                    event: 'click.action',
                    click: useSnakeCaseFilter(menuItem.label),
                    click_chapter1: 'menu',
                    click_chapter2: 'link',
                  })
                "
                >{{ menuItem.label
                }}<SvgIcon v-if="menuItem.icon" :name="`func_${menuItem.icon}`"
              /></NuxtLink>
              <template v-if="menuItem.sub_menu?.length">
                <button
                  :aria-expanded="currentMainMenuItem === index"
                  class="menu-link has-submenu"
                  @click="toggleSubmenu(index, $event)"
                >
                  {{ menuItem.label
                  }}<SvgIcon
                    v-if="menuItem.icon"
                    :name="`func_${menuItem.icon}`"
                  />
                </button>
                <div class="sub-menu">
                  <button
                    v-if="!isDesktop"
                    class="close-submenu"
                    @click="hideSubmenu()"
                  >
                    {{ menuItem.label }}
                  </button>
                  <ul
                    v-if="
                      !menuItem.sub_menu_grid ||
                      menuItem.sub_menu_grid?.length === 0
                    "
                    class="sub-menu-list"
                  >
                    <li
                      v-for="submenuItem in menuItem.sub_menu"
                      :key="submenuItem._uid"
                      @click="
                        trackingStore.sendTrackingData({
                          event: 'click.navigation',
                          click: useSnakeCaseFilter(submenuItem.link_label),
                          click_chapter1: 'sub_menu',
                          click_chapter2: 'link',
                        })
                      "
                    >
                      <NuxtLink
                        class="sub-menu-link"
                        :to="strippedURL(submenuItem.link_url)"
                        :target="
                          submenuItem.link_url.target === '_blank'
                            ? '_blank'
                            : '_self'
                        "
                      >
                        {{ submenuItem.link_label }}</NuxtLink
                      >
                    </li>
                  </ul>
                  <div
                    v-if="menuItem.sub_menu_grid?.length > 0"
                    class="sub-menu-wrapper"
                  >
                    <SubMenuGrid :grid="menuItem.sub_menu_grid" />
                    <div
                      v-if="
                        menuItem.sub_menu_grid?.length < 4 && menuItem.has_push
                      "
                      class="push-wrapper"
                    >
                      <MediaTextCta
                        :blok="pushData(menuItem)"
                        :level="2"
                        :is-centered="false"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </li>
          </ul>
          <ul
            v-if="content.corporate_menu?.length"
            class="corporate-menu hidden-tablet"
          >
            <li
              v-for="(menuItem, index) in content.corporate_menu"
              :key="menuItem._uid"
              :class="currentCorporateMenuItem === index ? 'current' : ''"
            >
              <NuxtLink
                v-if="menuItem.link && !menuItem.sub_menu?.length"
                class="menu-link"
                :to="strippedURL(menuItem.link)"
                :target="menuItem.link.target || '_self'"
                >{{ menuItem.label
                }}<SvgIcon v-if="menuItem.icon" :name="`func_${menuItem.icon}`"
              /></NuxtLink>
              <template v-if="menuItem.sub_menu?.length">
                <button
                  :aria-expanded="currentCorporateMenuItem === index"
                  class="menu-link has-submenu"
                  @click="
                    toggleCorporateSubmenu(index, $event);
                    trackingStore.sendTrackingData({
                      event: 'click.navigation',
                      click: useSnakeCaseFilter(menuItem.label),
                      click_chapter1: 'push_contact',
                      click_chapter2: 'link',
                    });
                  "
                >
                  {{ menuItem.label
                  }}<SvgIcon
                    v-if="menuItem.icon"
                    :name="`func_${menuItem.icon}`"
                  />
                </button>
                <div class="sub-menu">
                  <button class="close-submenu" @click="hideSubmenu()">
                    {{ menuItem.label }}
                  </button>
                  <ul class="sub-menu-list">
                    <li
                      v-for="submenuItem in menuItem.sub_menu"
                      :key="submenuItem._uid"
                    >
                      <NuxtLink
                        class="sub-menu-link"
                        :to="strippedURL(submenuItem.link_url)"
                        :target="
                          submenuItem.link_url.target === '_blank'
                            ? '_blank'
                            : '_self'
                        "
                      >
                        {{ submenuItem.link_label }}</NuxtLink
                      >
                    </li>
                  </ul>
                </div>
              </template>
            </li>
          </ul>
        </nav>
        <div
          v-if="content.contact_cta_label"
          class="contact-cta-wrapper parent-bg hidden-tablet"
        >
          <SimpleButton
            solid
            @click="
              openContactModal();
              trackingStore.sendTrackingData({
                event: 'click.action',
                click: useSnakeCaseFilter(content.contact_cta_label),
                click_chapter1: 'menu',
                click_chapter2: 'CTA',
              });
            "
            >{{ content.contact_cta_label }}</SimpleButton
          >
        </div>
      </div>
    </div>
    <GenericModal ref="contactModal" css-class="contactModal" height-auto>
      <StoryblokComponent
        v-if="content.contact_infos?.length === 1"
        :blok="content.contact_infos[0]"
      />
    </GenericModal>
  </header>
</template>

<script setup>
const config = useRuntimeConfig();
const trackingStore = useTrackingStore();
const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
});
const isDesktop = inject("isDesktop");
const { content } = toRefs(props); // eslint-disable-line
const isScrolledPage = ref(false);

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
  handleScroll();
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});

const handleScroll = () => {
  window.scrollY > headerHeight.value
    ? (isScrolledPage.value = true)
    : (isScrolledPage.value = false);
};

// Modal

const contactModal = ref(null);

const closeContactModal = () => {
  contactModal.value && contactModal.value.closeModal();
};
const openContactModal = () => {
  contactModal.value && contactModal.value.openModal();
  hideSubmenu();
};

// Submenu

const hasOpenedSubMenu = ref(false);
const hasOpenedCorporateSubMenu = ref(false);
let currentMainMenuItem = ref(null);
let currentCorporateMenuItem = ref(null);
let isMobileMenuOpened = ref(false);

const toggleSubmenu = (index, event) => {
  currentCorporateMenuItem.value = null;
  hasOpenedSubMenu.value =
    currentMainMenuItem.value === index ? !hasOpenedSubMenu.value : true;
  if (hasOpenedSubMenu.value) {
    currentMainMenuItem.value = index;
    document.body.classList.add("no-scroll");
    if (isMobileMenuOpened.value) {
      setTimeout(() => {
        getSubMenuSpacers();
      });
    }
  } else {
    currentMainMenuItem.value = null;
    document.body.classList.remove("no-scroll");
    event.target.blur();
  }
};

const toggleCorporateSubmenu = (index, event) => {
  currentMainMenuItem.value = null;
  hasOpenedCorporateSubMenu.value =
    currentCorporateMenuItem.value === index
      ? !hasOpenedCorporateSubMenu.value
      : true;
  if (hasOpenedCorporateSubMenu.value) {
    currentCorporateMenuItem.value = index;
  } else {
    currentCorporateMenuItem.value = null;
    event.target.blur();
  }
};

const hideSubmenu = () => {
  currentMainMenuItem.value = null;
  currentCorporateMenuItem.value = null;
  hasOpenedSubMenu.value = false;
  document.body.classList.remove("no-scroll");
};

const showHideMobileMenu = () => {
  isMobileMenuOpened.value = !isMobileMenuOpened.value;
  if (!isMobileMenuOpened.value) {
    hasOpenedSubMenu.value = false;
    document.body.classList.remove("no-scroll");
  } else {
    document.body.classList.add("no-scroll");
  }
};

const countryPrefix = config.public["storyblokCountryPrefix"];

// Header height

const headerRef = ref(null);
const mobileHeaderRef = ref(null);
const { height: headerHeight } = useElementSize(headerRef, undefined, {
  box: "border-box",
});
const { height: mobileHeaderHeight } = useElementSize(
  mobileHeaderRef,
  undefined,
  { box: "border-box" }
);
const bodyStyle = computed(() => {
  const height = mobileHeaderHeight.value || headerHeight.value || 0;
  return `--header-height: ${height}px`;
});

useHead({
  bodyAttrs: {
    style: bodyStyle,
  },
});

const logoDimensions = computed(() => {
  const dxd = content.value.logo?.filename
    .split("/")
    .filter((el) => el.match(/\d+x\d+/))[0];

  if (dxd) {
    const ratio = dxd.split("x")[0] / dxd.split("x")[1];
    const height = 76;

    return [Math.floor(Math.floor(height) * ratio), Math.floor(height)];
  }

  return ["", ""];
});

const locatorMenuItem = computed(() => {
  const item = content.value.main_menu.filter(
    (item) => item.icon === "locator"
  );
  return item[0];
});

const minNavMenu = computed(() => {
  return content.value.main_menu.filter((item) => item.icon != "locator");
});

const pushData = (menuItem) => {
  return createMediaTextCta({
    _uid: menuItem._uid,
    filename: menuItem.push_picture?.filename,
    title: menuItem.push_title?.content,
    text: menuItem.push_description?.content,
    ctaUrl: menuItem.push_cta_link?.url
      ? menuItem.push_cta_link?.url
      : menuItem.push_cta_link?.story?.url,
    ctaLabel: menuItem.push_cta_label,
    isCtaPlain: true,
  });
};

const getSubMenuSpacers = () => {
  let styles = "";
  headerRef.value
    ?.querySelectorAll(
      "nav:not(.min-nav) .sub-menu-grid .sub-menu-column.has-spacer"
    )
    .forEach((element, index) => {
      if (element.previousSibling.classList?.contains("sub-menu-column")) {
        const spacer = element.previousSibling.querySelector(
          ".sub-menu-list-item"
        )?.offsetHeight;
        if (spacer) {
          styles = styles + `--sub-menu-spacer-${index}: ${spacer}px;`;
        }
      }
    });

  useHead({
    bodyAttrs: {
      style: styles,
    },
  });
};
</script>

<style lang="scss">
body.header-absolute {
  .global-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
}

body:not(.header-white) {
  .global-header {
    .global-header-inner,
    .min-nav,
    .sub-menu {
      @include pair-5;
    }
  }
}

body.header-white {
  .global-header {
    .global-header-inner,
    .min-nav,
    .sub-menu {
      @include pair-7;

      .contact-cta-wrapper .simple-button {
        color: var(--solid-07);
      }
    }
  }
}

body:not(.header-facility) {
  @include for-tablet-landscape-up {
    .contact-cta-wrapper .simple-button {
      color: var(--solid-07);
    }
  }
}

body.header-facility {
  .contact-cta-wrapper .simple-button {
    color: var(--solid-07);
  }

  @include for-tablet-landscape-up {
    .global-header {
      .contact-cta-wrapper {
        @include pair-5;
      }

      .contact-cta-wrapper .simple-button {
        color: var(--text-100);
        background-color: var(--parent-bg);

        &::before {
          background: var(--text-100);
        }
      }
    }
  }
}

body.header-white.header-facility {
  @include for-tablet-landscape-up {
    .global-header .contact-cta-wrapper {
      @include pair-7;
    }
  }
}
</style>

<style lang="scss" scoped>
.global-header {
  position: relative;
  z-index: $z-index-menu;
  max-width: $header-width;
  margin: 0 auto;
  &.is-header-active {
    position: fixed;
    @include for-tablet-landscape-up {
      position: relative;
    }
    .menu-wrapper {
      display: flex;
    }
  }
  &-inner {
    width: 100vw;
    border-radius: 0 0 $radius-xs $radius-xs;
    @include for-tablet-landscape-up {
      width: 100%;
      height: auto;
      border-radius: 0 0 $radius-s $radius-s;
    }
  }
  a {
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    text-decoration: none;
    gap: 0.1rem;
    cursor: pointer;
  }
}

.meta-nav {
  width: 100%;
  height: 3.25rem;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1.5rem;
  align-items: center;
  padding: 0.88rem col-span();
  @include for-tablet-landscape-up {
    height: auto;
    grid-template-columns: 1fr 12rem 1fr;
    padding: 0.75rem 2.025% 1rem;
    border-bottom: $border-grey;
  }

  & > a {
    @include for-tablet-landscape-up {
      justify-content: center;
    }
  }
  .logo {
    color: var(--brand);
    width: auto;
    height: 1.5rem;
    @include for-tablet-landscape-up {
      height: 3rem;
    }
  }
}

.menu-wrapper {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 3.25rem);
  height: calc(100dvh - 3.25rem);
  overflow-y: auto;
  @include for-tablet-landscape-up {
    display: block;
    height: auto;
    overflow-y: inherit;
  }
}

.corporate-menu {
  display: flex;
  flex-direction: column;
  gap: 1.75rem 0;
  padding: 2.5rem col-span();
  @include for-tablet-landscape-up {
    align-items: center;
    flex-direction: row;
    gap: 2rem;
    padding: 0;
    margin-right: 1.5rem;
  }
  & > li {
    @include for-tablet-landscape-up {
      position: relative;
    }
  }
  .sub-menu {
    @include for-tablet-landscape-up {
      border-radius: $radius-s;
      border: 1px solid transparent;
      right: auto;
    }
    &-list {
      @include for-tablet-landscape-up {
        gap: 0.62rem 0;
      }
    }
    &-link {
      @include for-tablet-landscape-up {
        @include size-2xs;
        color: var(--text-100);
        margin-left: 0;
        white-space: nowrap;

        &::before {
          display: none;
        }
      }
    }
  }
  .current .sub-menu {
    @include for-tablet-landscape-up {
      padding: 1.125rem 1.25rem;
      top: 0;
      border: $border-grey;
    }
  }
}
.contact-cta-wrapper {
  padding: 1.25rem col-span();
  text-align: right;
  border-top: $border-grey;
  @include for-tablet-landscape-up {
    padding: 0;
    border-top: 0;
    display: flex;
    align-items: center;
    justify-content: right;
  }
}

.main-menu {
  position: relative;
  &-list {
    display: flex;
    flex-direction: column;
    gap: 1.75rem 0;
    padding: 2.5rem col-span();
    border-bottom: $border-grey;
    @include for-tablet-landscape-up {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 0.75rem 1rem;
      padding: 1.38rem 2.025%;
      border-bottom: 0;
      width: fit-content;
      margin: 0 auto;
      @include for-desktop-up {
        column-gap: 3rem;
      }
      .main-menu-item {
        z-index: 101;
      }
      .menu-link {
        text-align: center;
      }
    }
  }
}

.min-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 2.025%;
  position: fixed;
  top: 0;
  z-index: $z-index-menu;
  width: 100%;
  max-width: $header-width;
  border-radius: 0 0 $radius-s $radius-s;
  border-right: 1px solid var(--solid-05);
  border-bottom: 1px solid var(--solid-05);
  border-left: 1px solid var(--solid-05);

  .wrapper {
    display: flex;
  }
  .logo {
    max-width: 6.25rem;
    height: 1.625rem;
    width: auto;
    position: relative;
    z-index: calc($z-index-menu + 1);
  }
  .main-menu-list {
    padding: 0;
    gap: 0 2rem;
    flex-wrap: nowrap;

    @include for-desktop-up {
      gap: 0 3rem;
    }
    .main-menu-item:first-child {
      margin-left: 1rem;

      @include for-desktop-up {
        margin-left: 2.75rem;
      }
    }
    .main-menu-item:last-child {
      margin-right: 0.5rem;

      @include for-desktop-up {
        margin-right: 2.75rem;
      }
    }
    .menu-link {
      position: relative;
      z-index: calc($z-index-menu + 1);
    }
  }
  .cta-list {
    display: flex;
    flex-shrink: 0;
    align-items: center;
  }
  .locator-cta {
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 2.75rem;
    border: 2px solid var(--text-100);
    display: flex;
    justify-content: center;
    position: relative;
    z-index: calc($z-index-menu + 1);

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .contact-cta-wrapper {
    margin-left: 0.625rem;
  }

  .current .sub-menu {
    position: fixed;
    max-width: $header-width;
    width: 100%;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
}
.sub-menu {
  position: absolute;
  z-index: $z-index-menu;
  left: 0;
  right: 0;
  display: none;
  overflow: auto;
  @include for-tablet-landscape-up {
    display: block;
    border-radius: 0 0 $radius-s $radius-s;
    visibility: none;
    height: 0;
    opacity: 0;
    transition: opacity 0.3s linear;
  }
  &-list {
    display: flex;
    flex-direction: column;
    gap: 1.75rem 0;
    @include for-tablet-landscape-up {
      gap: 2rem 0;

      li {
        overflow: hidden;
      }
    }
  }
  &-link {
    font-size: 1.125rem;
    @include for-tablet-landscape-up {
      font-size: 2rem;
      color: var(--text-50);
      transition: all 0.3s linear;
      margin-left: -1.5rem;
      &:hover,
      &.router-link-active {
        color: var(--text-100);
        margin-left: 0;
      }
      &::before {
        content: "";
        width: 1.5rem;
        height: 1.5rem;
        background-image: url("../assets/svg/func_right.svg");
      }
    }
  }
}

.current .sub-menu {
  display: block;
  padding: 2.5rem col-span();
  top: 0;
  height: calc(100vh - 3.25rem);
  height: calc(100dvh - 3.25rem);
  @include for-tablet-landscape-up {
    visibility: visible;
    opacity: 1;
    top: auto;
    height: auto;
    padding: 2.75rem 2.025%;
  }
}

.meta-nav .current .sub-menu {
  margin-top: 1.875rem;
}

.menu-burger,
.close-submenu,
.menu-link {
  font-weight: 700;
  border: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.menu-link {
  display: flex;
  width: 100%;
  text-align: left;
  font-size: 1.125rem;
  justify-content: space-between;
  @include for-tablet-landscape-up {
    display: inline-flex;
    width: auto;
    font-size: 1rem;
  }

  &.has-submenu {
    @include for-tablet-landscape-up {
      align-items: center;
    }
    background: url("../assets/svg/func_right.svg") right center no-repeat;
    padding-right: 1.8rem;
    @include for-tablet-landscape-up {
      background-image: url("../assets/svg/func_down.svg");
    }
    &[aria-expanded="true"] {
      background-image: url("../assets/svg/func_up.svg");
    }
    svg {
      margin-right: 0.1875rem;
    }
  }
}

.close-submenu {
  display: block;
  width: 100vw;
  margin: 0 col-span(-1) 2.5rem;
  text-align: center;
  font-size: 1.125rem;
  padding: 0.75rem col-span();
  border-bottom: $border-grey;
  &::before {
    position: absolute;
    z-index: 1;
    left: col-span();
    content: "";
    width: 1.5rem;
    height: 1.5rem;
    background-image: url("../assets/svg/func_left.svg");
  }
}

@include for-tablet-landscape-up {
  .menu-link:hover,
  .current .menu-link {
    text-decoration: underline;
  }
}

.mask {
  position: fixed;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $background-overlay;
}

.sub-menu-wrapper {
  @include for-tablet-landscape-up {
    display: flex;
    justify-content: space-between;
  }

  &:has(.media-text-cta) {
    @include for-tablet-landscape-up {
      gap: col-span(1);
    }
  }
}

.sub-menu-grid.col-1:has(+ .push-wrapper) {
  @include for-tablet-landscape-up {
    width: calc(50% - col-span(1));
  }
}

.sub-menu-grid.col-2:has(+ .push-wrapper) {
  @include for-tablet-landscape-up {
    width: calc(100% / 3 * 2);
  }
}

.sub-menu-grid.col-3:has(+ .push-wrapper) {
  @include for-tablet-landscape-up {
    width: calc(100% / 4 * 3);
  }
}

.sub-menu-grid.col-1 + .push-wrapper {
  @include for-tablet-landscape-up {
    width: 50%;
  }
  :deep(.media-text-cta) {
    @include for-tablet-landscape-up {
      width: 100%;
    }
  }
}

.sub-menu-grid.col-2 + .push-wrapper {
  @include for-tablet-landscape-up {
    width: calc(100% / 3);
  }
}

.sub-menu-grid.col-3 + .push-wrapper {
  @include for-tablet-landscape-up {
    width: calc(100% / 4);
  }
}

.sub-menu-grid:not(.col-1) + .push-wrapper .media-text-cta {
  @include for-tablet-landscape-up {
    flex-direction: column;
    padding-right: 0.625rem;
    padding-bottom: 1.5rem;
    gap: 1.5rem;
    width: 100%;
  }

  :deep(.media) {
    @include for-tablet-landscape-up {
      width: 100%;
    }
  }

  :deep(.text-cta-wrapper) {
    @include for-tablet-landscape-up {
      width: 100%;
    }
  }

  :deep(.text-wrapper) {
    @include for-tablet-landscape-up {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
}

:deep(.media-text-cta) {
  @include pair-1;
  margin-top: 2rem;
  padding-bottom: 1.5rem;

  @include for-tablet-portrait-up {
    margin-top: 3.25rem;
    flex-direction: row;
    padding-right: 2.5rem;
    padding-bottom: 0.625rem;
    gap: 2rem;
  }

  @include for-tablet-landscape-up {
    margin-top: 0;
    padding-right: 2rem;
    width: 50%;
  }

  .media {
    @include for-tablet-portrait-up {
      width: 50%;
    }
  }

  .text-cta-wrapper {
    width: col-span(16);

    @include for-tablet-portrait-up {
      width: 50%;
    }
  }

  .text-wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  .simple-button:not(.link) {
    font-size: 1rem;
    text-decoration: underline;
    min-height: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;

    @include for-tablet-portrait-up {
      font-size: 1.125rem;
    }
  }
}
</style>
